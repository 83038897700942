<template>
  <div>
    <!-- Check if assign action is allowed -->
    <ResourceAllowedOperations
      v-if="clientActions === null"
      v-show="false"
      v-slot="{ allowsActions }"
      :resource-name="clientsResourceName"
      @change:allowsActions="allowsClientActions = $event"
    >
      <EntityActions
        v-if="allowsActions"
        :entity-id="clientId"
        :resource-name="clientsResourceName"
        @change="setClientActions($event)"
        @loading="onLoadingClientActions"
      />
    </ResourceAllowedOperations>
    <!-- / Check end -->

    <!-- Check if delete is allowed -->
    <ResourceAllowedOperations
      :resource-name="resourceName"
      @change:allowsDelete="canDelete = $event"
    />
    <!-- / Check end -->

    <WidgetContainer :style="{ height: widgetContainerHeight }">
      <v-toolbar
        v-if="widget.title"
        ref="toolbar"
        class="toolbar3"
        dense
        flat
        :height="toolbarHeight"
        short
      >
        <v-toolbar-title
          :style="{ fontSize: '1.125rem' }"
          v-text="$t(widget.title)"
        />
      </v-toolbar>

      <WidgetFactory
        :key="JSON.stringify(widget)"
        :props-data="{
          container,
          style: { height: widgetHeight && `${widgetHeight}px` },
        }"
        :widget="widget"
        v-on="$listeners"
      />
    </WidgetContainer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ClientActions } from '@/tt-entity-design/src/components/clients/types'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { DataTableWidgetModel } from '@/tt-widget-components'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { VENDOR_STATUS_COLORS } from '@/tt-entity-design/src/components/vendors/constants'
import {
  FilterOperatorType,
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const TOOLBAR_HEIGHT = 60

export default Vue.extend({
  name: 'AssignVendorsWidget',
  props: {
    height: { type: Number, default: undefined },
    clientId: { type: Number, required: true },
  },
  data() {
    return {
      clientActions: null as Action[] | null,
      canDelete: false,
      isLoadingActions: false,
      allowsClientActions: false,
      unsubscribe: null as UnsubscribeFunction | null,
    }
  },
  computed: {
    resourceName() {
      return Resources.SITE_VENDORS
    },
    clientsResourceName() {
      return Resources.CLIENTS
    },
    container(): WidgetContainerInterface {
      return {
        height: this.widgetHeight,
        hideTitle: true,
        type: WidgetContainerType.STANDALONE,
      }
    },
    allowUnassignVendors(): boolean {
      return this.canDelete
    },
    columns(): ColumnInputDefinition[] | undefined {
      const columns: ColumnInputDefinition[] = [
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.id',
          attributeName: 'vendor.id',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.name',
          attributeName: 'vendor.name',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.fullName',
          attributeName: 'vendor.fullName',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.mainEmail',
          attributeName: 'vendor.mainEmail',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.primaryPhone',
          attributeName: 'vendor.primaryPhone',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.priority',
          attributeName: 'priority',
          style: {
            textAlign: 'Center',
            cssClass: 'text-center',
          },
        },
        {
          attributeName: 'status',
          component: {
            is: 'ChipLabelEnum',
            props: {
              name: 'status',
              colorsMap: VENDOR_STATUS_COLORS,
            },
          },
        },
      ]
      const actionsColumn: ColumnInputDefinition = {
        headerText: 'vendors.tabs.assign-vendors.table.headers.actions',
        component: {
          is: 'AssignedSiteVendorsActionsColumn',
          props: {
            clientId: this.clientId,
          },
        },
        style: {
          width: 100,
        },
      }

      return this.allowUnassignVendors ? [...columns, actionsColumn] : columns
    },
    toolbarHeight(): number {
      return this.widget.title ? TOOLBAR_HEIGHT : 0
    },
    widget(): DataTableWidgetModel | null {
      return {
        title: '',
        is: WidgetName.DATA_TABLE_WIDGET,
        component: {
          columns: this.columns,
        },
        query: {
          resource: this.resourceName,
          filters: [
            {
              attribute: 'site',
              operator: FilterOperatorType.EQUAL,
              value: this.clientId,
            },
          ],
          sort: [
            {
              direction: 'ASC',
              attribute: 'priority',
            },
          ],
          includeInactive: true,
        },
        allowActions: true,
        toolbar: {
          filterOptions: {
            allowSearch: true,
            filters: [],
          },
          displayCounts: true,
        },
      }
    },
    widgetContainerHeight(): string | undefined {
      return this.height && `${this.height}px`
    },
    widgetHeight(): number {
      return this.height && this.height - this.toolbarHeight
    },
  },
  created() {
    this.unsubscribe = this.$eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ entityId, actionName, resource }) => {
        const syncActions = () =>
          resource === Resources.CLIENTS &&
          [
            ClientActions.AssignVendors,
            ClientActions.PrioritizeVendors,
          ].includes(actionName)

        if (syncActions() && entityId === this.clientId) {
          this.$nextTick(() => {
            this.$eventManager.dispatchEvent(
              EntityIntentTypes.RESOURCE_UPDATED,
              {
                resource: this.resourceName,
              },
            )
          })
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe()
  },
  methods: {
    setClientActions(actions: Action[]): void {
      this.clientActions = actions
    },
    onLoadingClientActions(loading: boolean): void {
      this.isLoadingActions = loading
    },
  },
})
</script>
